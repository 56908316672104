// Polyfills for IE11
import '@babel/polyfill';
import 'url-polyfill'; // needed for plyr in ie11


// Polyfill for CustomEvent, needed for plyr in ie11
(function () {

  if ( typeof window.CustomEvent === "function" ) return false;

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();


import '../sass/main.scss';

export { default as $ } from 'jquery';

// Progress Bar
import ProgressBar from './components/progress-bar';
ProgressBar();

// Carousel
import Carousel from './components/carousel';
Carousel();

// Datepicker
import Datepicker from './components/datepicker';
Datepicker();

// Mobile menu
import MobileMenu from './components/menu-mobile';
export const mobileMenu = new MobileMenu();

// Search reveal
import SearchReveal from './components/search';
export const searchReveal = new SearchReveal();

// Listing Filters
import ListingFilters from './components/listing-filters';
ListingFilters();

// Accordion
import Accordion from './components/accordion';
export const accordion = new Accordion();

// Tabs
import Tabs from './components/tabs';
export const tabs = new Tabs();

// Smooth scroll
import SmoothScroll from './components/smooth-scroll';
export const smoothScroll = new SmoothScroll();

// Toggle filters
import ToggleFilters from './components/toggle-filters';
export const toggleFilters = new ToggleFilters();

// Toggle mobile filters
import ToggleMobileFilters from './components/mobile-filters';
export const toggleMobileFilters = new ToggleMobileFilters();

// Cookie warnings
import CookieWarning from './components/eu-cookie';
export const cookieWarning = new CookieWarning();

// Beta banner
import BetaBanner from './components/beta-banner';
export const betaBanner = new BetaBanner();

// Message bar
import MessageBarWarning from './components/message-bar';
export const messageBarWarning = new MessageBarWarning();

// Sticky Info
import StickyInfo from './components/sticky-info';
StickyInfo();

// Browse
import Browse from './components/browse';
export const browse = Browse;

// Google maps
import Map from './components/maps';

// Styleguide menu-secondary
import StyleguideMenu from './components/styleguide';
StyleguideMenu();

// Load more
import LoadMore from './components/load-more';
LoadMore();

import VideoPlayer from './components/video-player';
VideoPlayer();

import searchSuggestions from './components/search-suggestions';
searchSuggestions();

import MenuToggle from 'nhsuk-frontend/packages/components/header/menuToggle';
MenuToggle();

// Charity Dropdown menu
import CharityPrimaryNav from './components/charity-primary-nav';
export const charityPrimaryNav = new CharityPrimaryNav();